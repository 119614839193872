<template>
  <AuthWrapper>
    <!-- <p class="auth-notice">Already have an account? <router-link to="/auth/login">Sign In</router-link></p> -->
    <div class="auth-contents">
      <a-spin :spinning="isLoading">
        <a-form name="register" layout="vertical" ref="formRef" :model="formState" :rules="userRules">
          <sdHeading as="h3"> สมัครสมาชิก <span class="color-secondary">ครูแคร์</span> </sdHeading>
          <!-- <a-form-item label="โค้ดการสมัคร" name="token">
            <a-input v-model:value="formState.token" placeholder="Authentication Code" :disabled="false" />
          </a-form-item> -->
          <a-form-item label="ชื่อจริง" name="firstname">
            <a-input v-model:value="formState.firstname" placeholder="ชื่อจริง" />
          </a-form-item>
          <a-form-item label="ชื่อกลาง" name="middlename">
            <a-input v-model:value="formState.middlename" placeholder="ชื่อกลาง" />
          </a-form-item>
          <a-form-item label="นามสกุล" name="lastname">
            <a-input v-model:value="formState.lastname" placeholder="นามสกุล" />
          </a-form-item>
          <a-form-item name="email" label="อีเมล์">
            <a-input v-model:value="formState.email" placeholder="เช่น name@example.com" />
          </a-form-item>
          <a-form-item label="ชื่อสถานศึกษา(รวมคำนำหน้า)" name="schoolName">
            <a-input v-model:value="formState.schoolName" placeholder="เช่น โรงเรียนอนุบาลครูแคร์" />
          </a-form-item>
          <!-- <div class="auth-form-action">
          <a-checkbox v-model:checked="checked" @change="onChange">
            Creating an account means you’re okay with our Terms of Service and Privacy Policy
          </a-checkbox>
        </div> -->
          <a-form-item>
            <sdButton class="btn-create" @click.prevent="handleSubmit" type="primary" size="large">
              สมัครสมาชิก
            </sdButton>
          </a-form-item>
        </a-form>
      </a-spin>
    </div>
  </AuthWrapper>
</template>
<script>
import { FacebookOutlined, TwitterOutlined } from '@ant-design/icons-vue';
import { AuthWrapper } from './style';
import { computed, reactive, ref, watch, toRaw } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const userRules = reactive({
  firstname: [
    {
      required: true,
      message: 'กรุณาระบุชื่อจริง',
    },
  ],
  lastname: [
    {
      required: true,
      message: 'กรุณาระบุนามสกุล',
    },
  ],
  email: [
    {
      required: true,
      message: 'กรุณาระบุอีเมล์ที่ใช้งานจริง',
    },
    {
      //eslint-disable-next-line
      pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      message: 'กรุณาระบุอีเมล์ให้ถูกต้อง',
      trigger: 'change',
    },
  ],
  schoolName: [
    {
      required: true,
      message: 'กรุณาระบุชื่อสถานศึกษาของคุณ',
    },
  ],
});

const SignUp = {
  name: 'Line-SignUp',
  components: { FacebookOutlined, TwitterOutlined, AuthWrapper },
  props: {
    query: Object,
  },
  setup() {
    const formRef = ref();
    const checked = ref(null);

    const router = useRouter();
    const { state, dispatch } = useStore();

    const lineToken = computed(() => state.line.lineToken);
    const customToken = computed(() => state.line.customToken);
    const isLoading = computed(() => state.line.loading);

    const formState = reactive({
      firstname: '',
      middlename: '',
      lastname: '',
      email: '',
      schoolName: '',
      token: lineToken.value,
    });

    const handleSubmit = () => {
      formRef.value
        .validate()
        .then(() => {
          const values = toRaw(formState);
          dispatch('lineAuthSignUp', values);
        })
        .catch(err => {
          console.log('error', err);
        });
    };

    const onChange = e => {
      checked.value = e.target.checked;
    };

    const handleFbLogin = () => {
      dispatch('firebaseAuthLoginWithLine', customToken.value);
    };

    const handleLoggedIn = () => {
      router.push({ name: 'wizard-starter' });
    };

    watch(
      () => state.line.customToken,
      token => {
        if (!token) return;
        handleFbLogin();
      },
    );

    watch(
      () => state.firebase.uid,
      uid => {
        if (!uid) return;
        handleLoggedIn();
      },
    );

    watch(
      () => state.line.uid,
      uid => {
        if (!uid) return;
        handleLoggedIn();
      },
    );

    return {
      onChange,
      handleSubmit,
      formState,
      formRef,
      checked,
      isLoading,
      userRules,
    };
  },
};

export default SignUp;
</script>
